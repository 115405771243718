import * as d3 from 'd3';
import React from 'react';

import type { TimeChartItem } from 'ui/shared/chart/types';

interface Props extends React.SVGProps<SVGPathElement> {
  xScale: d3.ScaleTime<number, number> | d3.ScaleLinear<number, number>;
  yScale: d3.ScaleTime<number, number> | d3.ScaleLinear<number, number>;
  startColor?: string;
  stopColor?: string;
  color?: string;
  data: Array<TimeChartItem>;
  animation: 'left' | 'fadeIn' | 'none';
}

const ChartLine = ({ xScale, yScale, data, animation, startColor, stopColor, color = 'grey', ...props }: Props) => {
  const ref = React.useRef<SVGPathElement>(null);

  console.log(data);

  // Define different types of animation that we can use
  const animateLeft = React.useCallback(() => {
    const totalLength = ref.current?.getTotalLength() || 0;
    d3.select(ref.current)
      .attr('opacity', 1)
      .attr('stroke-dasharray', `${ totalLength },${ totalLength }`)
      .attr('stroke-dashoffset', totalLength)
      .transition()
      .duration(750)
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0);
  }, []);

  const animateFadeIn = React.useCallback(() => {
    d3.select(ref.current)
      .transition()
      .duration(750)
      .ease(d3.easeLinear)
      .attr('opacity', 1);
  }, []);

  const noneAnimation = React.useCallback(() => {
    d3.select(ref.current).attr('opacity', 1);
  }, []);

  React.useEffect(() => {
    const ANIMATIONS = {
      left: animateLeft,
      fadeIn: animateFadeIn,
      none: noneAnimation,
    };
    const animationFn = ANIMATIONS[animation];
    window.setTimeout(animationFn, 100);
  }, [ animateLeft, animateFadeIn, noneAnimation, animation ]);

  // Recalculate line length if scale has changed
  React.useEffect(() => {
    if (animation === 'left') {
      const totalLength = ref.current?.getTotalLength();

      d3.select(ref.current).attr(
        'stroke-dasharray',
        `${ totalLength },${ totalLength }`,
      );
    }
  }, [ xScale, yScale, animation ]);

  const line = d3.line<TimeChartItem>()
    .x((d) => xScale(d.date))
    .y((d) => yScale(d.value))
    .curve(d3.curveMonotoneX);

  return (
    <>
      { startColor && stopColor ? (
        <defs>
          <linearGradient id="gradientId" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={ startColor }/>
            <stop offset="100%" stopColor={ stopColor }/>
          </linearGradient>
        </defs>
      ) : null }

      <path
        ref={ ref }
        d={ line(data) || undefined }
        strokeWidth={ 1 }
        strokeLinecap="round"
        fill="none"
        opacity={ 0 }
        stroke={ startColor && stopColor ? `url(#gradientId)` : color }
        { ...props }
      />
    </>
  );
};

export default React.memo(ChartLine);
